<template>
  <div class="body">
    <v-navigation-drawer app class="ma-0 pa-0" v-model="mostrar">
      <opciones />
    </v-navigation-drawer>

    <v-app-bar
      app
      class="mb-0 pb-0"
      :class="[
        {
          perdida:
            totalVenta.flete -
              totalOpcion.flete +
              totalVenta.gasto -
              totalOpcion.gasto +
              totalVenta.aduana -
              totalOpcion.aduana +
              totalVenta.almacen -
              totalOpcion.almacen <
            150,
        },
        {
          ganancia:
            totalVenta.flete -
              totalOpcion.flete +
              totalVenta.gasto -
              totalOpcion.gasto +
              totalVenta.aduana -
              totalOpcion.aduana +
              totalVenta.almacen -
              totalOpcion.almacen >
            150,
        },
      ]"
    >
      <v-app-bar-nav-icon @click="mostrar = !mostrar"></v-app-bar-nav-icon>
      <h3 color="white--text">
        EDITAR DE COTIZACIÓN: QUOTE-{{ datosPrincipales.quote }}
      </h3>
      <v-flex text-right>
        GANANCIA:
        {{
          currencyFormat(
            totalVenta.flete -
              totalOpcion.flete +
              totalVenta.gasto -
              totalOpcion.gasto +
              totalVenta.aduana -
              totalOpcion.aduana +
              totalVenta.almacen -
              totalOpcion.almacen
          )
        }}
      </v-flex>
      <v-flex text-right>
        <v-btn
          class="mx-1"
          color="light-blue darken-3"
          dark
          @click="copiarCotizacion"
        >
          <v-icon class="mx-1" dense small>mdi-content-copy</v-icon>
          COPIAR COTIZACIÓN
        </v-btn>
        <v-btn
          class="mx-1"
          color="teal"
          dark
          :to="{ path: '/editar_cotizacion/' + this.$route.params.id }"
          :disabled="datosPrincipales.statusmain == 0"
        >
          <v-icon class="mx-1" dense small>mdi-pencil</v-icon>
          EDITAR
        </v-btn>
      </v-flex>
    </v-app-bar>

    <v-container elevation="10" fluid>
      <v-toolbar elevation="0" class="pa-0 ma-0">
        <v-tabs centered>
          <v-tab @click="obtenerIdTab(0)">Solicitar</v-tab>
          <v-tab @click="obtenerIdTab(1)"> Costos de la Cotización</v-tab>
          <v-tab @click="obtenerIdTab(2)"> Notas de Costos </v-tab>
          <v-tab @click="obtenerIdTab(3)">Instructivo</v-tab>
          <v-tab @click="obtenerIdTab(4)">LLAMADAS</v-tab>
          <v-tab @click="obtenerIdTab(5)">Archivos</v-tab>
        </v-tabs>
      </v-toolbar>
      <v-tabs-items v-model="tabCotizar">
        <v-tab-item>
          <div class="row mt-3">
            <div class="col-12 col-lg-6 col-xl-6 col-md-6 pa-0">
              <datosPersonales tipo="ver" />
            </div>
            <div class="col-12 col-lg-6 col-xl-6 col-md-6 pa-0">
              <datosCarga id="carga" tipo="ver" />
            </div>
            <div class="col-12">
              <div class="col-12">
                <v-flex text-right>
                  <v-btn
                    small
                    color="info"
                    block
                    x-small
                    class="ml-5 mr-5"
                    @click="recargar"
                  >
                    RE - CARGAR (SERVICIOS Y COSTOS)
                    <v-icon class="mx-1">mdi-refresh </v-icon>
                  </v-btn>
                </v-flex>
              </div>
            </div>
            <div class="col-12">
              <datosServicios id="servicio" tipo="ver" />
            </div>
          </div>
        </v-tab-item>
        <v-tab-item>
          <cardCostos tipo="ver" />
        </v-tab-item>
        <v-tab-item>
          <cardNotas tipo="ver" />
        </v-tab-item>
        <v-tab-item>
          <instructivo tipo="ver" />
        </v-tab-item>
        <v-tab-item>
          <llamadas tipo="ver" />
        </v-tab-item>
        <v-tab-item>
          <div class="row">
            <div class="col-12 col-lg-6 col-xl-6 col-md-6 pa-5">
              <h3><p>LISTADO DE ARCHIVOS</p></h3>
              <listaArchivos tipo="ver" />
            </div>
            <div class="col-12 col-lg-6 col-xl-6 col-md-6 pa-5">
              <h3><p>CARGA DE ARCHIVOS</p></h3>
              <cargarAchivos />
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
    <v-dialog
      v-model="modalCargando"
      scrollable
      persistent
      :overlay="false"
      max-width="600px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title> Obteniendo datos ... </v-card-title>

        <v-card-text>
          <v-progress-linear
            color="deep-purple accent-4"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-footer app> </v-footer>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import funcion from "@/mixins/funciones";

import Swal from "sweetalert2";
import axios from "axios";
export default {
  mixins: [funcion],
  name: "editarCotizacionComponet",
  components: {
    opciones: () => import("@/components/comun/opcionesRegistro.vue"),
    datosPersonales: () =>
      import("@/components/Cotizacion/cardDatosPersonalesComponent.vue"),
    datosCarga: () =>
      import("@/components/Cotizacion/cardDatosCargaComponent.vue"),
    datosServicios: () =>
      import("@/components/Cotizacion/cardServicioComponent.vue"),
    cardCostos: () => import("@/components/Cotizacion/cardCostosComponent.vue"),
    cardNotas: () => import("@/components/Cotizacion/cardNotasComponent.vue"),
    instructivo: () =>
      import("@/components/Cotizacion/cardInstructivoComponet.vue"),
    llamadas: () =>
      import("@/components/editarCotizacion/cardLlamadasComponent.vue"),
    listaArchivos: () =>
      import("@/components/comun/listaArchivosComponent.vue"),
    cargarAchivos: () =>
      import("@/components/comun/cargarArchivosComponet.vue"),
  },
  data() {
    return {
      mostrar: false,
      tabCotizar: 0,
      carga: false,
      servicio: false,
      costo: true,
      nota: true,
      instructivo: true,
      modalCargando: true,
      hasChanges: true,
      statusUpdate: false,
      actualizacionCasillero: false,
    };
  },
  mounted() {
    this.getQuoteSales();
    this.cerrarModalObtenerDatos();
  },
  methods: {
    ...mapActions([
      "getQuoteStatus",
      "getEntities",
      "getModality",
      "getShipment",
      "getIncoterms",
      "getPortBegin",
      "getPortEnd",
      "getContainersList",
      "getBegEndList",
      "getCityPricing",
      "getModuleRole",
      "getInstructivoId",
      "getFilesQuote",
      "getMultiplicador",
      "obtenerDatosCliente",
      "getMarketingList",
      "getQuoteSales",
    ]),
    cerrarModalObtenerDatos() {
      setTimeout(() => (this.modalCargando = false), 3500);
    },
    modalCargandoF() {},

    obtenerIdTab(id) {
      let opciones = [
        {
          id: "0",
          nombre: "Datos Principales",
          class: id == 0 ? "active" : "inactive",
        },
        {
          id: "1",
          nombre: "Costos Cotización",
          class: id == 1 ? "active" : "inactive",
        },
        {
          id: "2",
          nombre: "Notas de Costos",
          class: id == 2 ? "active" : "inactive",
        },
        {
          id: "3",
          nombre: "Instructivo",
          class: id == 3 ? "active" : "inactive",
        },
      ];
      this.opcioneMenuLista(opciones);
      this.tabCotizar = id;
    },

    async obtenerDatos() {
      let valores = await this.obtenerDataApi(
        "post",
        process.env.VUE_APP_URL_MAIN + "getQuoteId",
        {
          id: this.$route.params.id,
        }
      );

      let containers = [];
      let nota = [];
      let imp = [];
      for (let i = 0; i < valores.length; i++) {
        if (valores[i].servicios) {
          let serv = [];
          valores[i].servicios.forEach((element) => {
            serv.push({
              id: element.id,
              id_begend: element.id_begend,
              esventaflag: 0,
              status: element.status,
              nameservice: element.nameservice,
              id_groupservices: element.id_groupservices,
              namegroupservice: element.nameservice,
              codegroupservices: element.codegroupservices,
              codeItemservices: element.codegroupservices,
            });
          });
          this.setServices(serv);
        }
        if (valores[i].containers) {
          containers = valores[i].containers;
        }
        if (valores[i].taxes) {
          imp = valores[i].taxes;
        }
        if (valores[i].costes) {
          let cost = [];
          valores[i].costes.forEach((element) => {
            cost.push({
              id: element.id,
              id_multiplicador: element.id_multiplicador
                ? element.id_multiplicador
                : "N",
              nameservice: element.nameservice,

              cif: element.cif,
              concepto: element.concepto,
              costounitario: element.costounitario,

              esopcionflag: element.esventaflag == 0 ? 1 : 0,

              esventaflag: element.esventaflag,

              created_at: element.created_at,
              esaduanaflag: element.esaduanaflag,
              esalmacenflag: element.esalmacenflag,
              eslocalflag: element.eslocalflag,
              esorigenflag: element.esorigenflag,
              id_proveedor: element.id_proveedor,
              id_quote: element.id_quote,
              seguro: element.seguro,
              status: element.status,
              updated_at: element.updated_at,
              valorMultiplicador: element.valorMultiplicador,
              minimo: element.minimo ? element.minimo : 0,
            });
          });

          this.setCostos(cost);
        }
        if (valores[i].notes) {
          valores[i].notes.forEach((element) => {
            nota.push({
              id: element.id,
              description: element.description,
              state: element.status,
            });
          });
        }
        if (valores[i].sales) {
          if (valores[i].sales.length > 0) {
            this.setQuoteSales(valores[i].ventasdetalles);
          } else {
            this.actualizacionCasillero = true;
          }
        }
      }

      //

      let contenedor = [];

      containers.forEach((container) => {
        contenedor.push({
          description: container.id_containers ? this.containersList.filter(
            (v) => v.value == container.id_containers
          )[0].text : '',
          cantidad: container.quantity,
          id: container.id_containers,
          idPrincipal: container.id,
        });
      });
      let datos = {};
      datos = {
        contacto: valores[0].contacto,
        identities: valores[0].id_vendedor,
        idPricing: valores[0].id_pricing,
        id: valores[0].id,
        idCliente: valores[0].id_entitie,
        idPortEnd: valores[0].iddestino,
        idincoterm: valores[0].idincoterms,
        idPortBegin: valores[0].idorigen,
        idmodality: valores[0].idsentido,
        idshipment: valores[0].idtipocarga,
        amount: valores[0].monto,
        bultos: valores[0].numerobultos,
        kg: valores[0].peso,
        rangeDays: valores[0].rangeDays,
        idstatus: valores[0].statusquote,
        name: valores[0].nombre,
        phone: valores[0].telefono,
        metroscc: valores[0].volumen,
        containers: contenedor,
        quote: valores[0].quote,
        proveedor: valores[0].proveedor ? valores[0].proveedor : "",
        telefonoproveedor: valores[0].telefonoproveedor
          ? valores[0].telefonoproveedor
          : "",
        direccionproveedor: valores[0].direccionproveedor
          ? valores[0].direccionproveedor
          : "",
        idprovincia: valores[0].idprovincia ? valores[0].idprovincia : 128,
        iddistrito: valores[0].iddistrito ? valores[0].iddistrito : 1279,
        descripcioncarga: valores[0].descripcionmercancia
          ? valores[0].descripcionmercancia
          : "",

        notesFixed: nota,
        statusmain: valores[0].statusmain,
        fecha_fin: valores[0].fecha_fin,
        fecha_inicio: valores[0].fecha_inicio,
        idmarketing: valores[0].id_marketing,
        tiempo_transito: valores[0].tiempo_transito,
        impuestos:
          imp.length > 0
            ? imp
            : [
                { type: 1, name: "FOB", percentage: 0, valor: null, orden: 1 },
                {
                  type: 2,
                  name: "FLETE",
                  percentage: 0,
                  valor: null,
                  orden: 2,
                },
                {
                  type: 3,
                  name: "SEGURO",
                  percentage: 1.5,
                  valor: null,
                  orden: 3,
                },
                {
                  type: 4,
                  name: "CIF",
                  percentage: null,
                  valor: null,
                  orden: 4,
                },
                {
                  type: 5,
                  name: "AD VALOREN",
                  percentage: 6,
                  valor: null,
                  orden: 5,
                },
                {
                  type: 6,
                  name: "ISC",
                  percentage: 0.0,
                  valor: null,
                  orden: 6,
                },
                {
                  type: 7,
                  name: "IPM",
                  percentage: 2.0,
                  valor: null,
                  orden: 7,
                },
                {
                  type: 8,
                  name: "IGV",
                  percentage: 16.0,
                  valor: null,
                  orden: 8,
                },
                {
                  type: 9,
                  name: "PERCEPCIONES",
                  percentage: 5.0,
                  valor: null,
                  orden: 9,
                },
              ],
      };
      this.getPortBegin(valores[0].idsentido);
      this.getPortEnd(valores[0].idsentido);
      this.getMultiplicador({
        id: valores[0].idtipocarga,
        containers: contenedor,
      });
      this.guardarDatosVer(datos);
      this.obtenerListadoLlamadas();
      this.getInstructivoId(this.$route.params.id);
      this.getCityPricing();
      this.getTown(valores[0].idprovincia ? valores[0].idprovincia : 128);
    },

    llamadas() {
      this.$router
        .push({
          name: "cardListadoLlamadaEditar",
          hash: "#llamadas",
          params: { id: this.$route.params.id },
        })
        .catch(() => {});
    },
    async obtenerListadoLlamadas() {
      let valores = await this.obtenerDataApi(
        "post",
        process.env.VUE_APP_URL_MAIN + "getCalls",
        {
          id_quote: this.$route.params.id,
          id_branch: JSON.parse(localStorage.getItem("branch")),
        }
      );
      this.setLlamadas(valores);
    },

    update() {
      /* GUARDAR SERVICIOS */

      let idshipment = this.datosPrincipales.idshipment.value
        ? this.datosPrincipales.idshipment.value
        : this.datosPrincipales.idshipment;
      if (
        (idshipment == 2 || idshipment == 5) &&
        this.datosPrincipales.containers.length <= 0
      ) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "SE REQUIERE AL MENOS UN CONTENEDOR",
        });
      } else if (
        idshipment != 2 &&
        idshipment != 5 &&
        !this.datosPrincipales.bultos
      ) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "SE REQUIERE LOS DATOS DE LA CARGA",
        });
      } else if (
        !this.datosPrincipales.idPortBegin ||
        !this.datosPrincipales.idPortEnd
      ) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "SE REQUIERE LOS DATOS DE LOS PUERTOS (ORIGEN Y DESTINO)",
        });
      } else if (this.costos.filter((v) => !v.id_multiplicador).length > 0) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Al costo no tiene multiplicador, verifique por favor.",
        });
      } else {
        let serv = [];

        this.services
          .filter((v) => v.esventaflag == 0)
          .forEach((element) => {
            serv.push({
              id: element.id,
              nameservice: element.namegroupservice,
              statusService: element.status,
              id_contenedor: element.idPrincipal,
              codegroupservices: element.codegroupservices,
              idBegEnd: element.id_begend,
              id_begend: element.id_begend,
            });
          });
        /* GUARDAR COSTOS */
        let cost = [];
        this.costos.forEach((element) => {
          let codemultiplicador = element.id_multiplicador
            ? this.multiplicador.filter(
                (v) => v.id == element.id_multiplicador
              )[0]
            : [];
          let subTotal = 0;
          if (codemultiplicador) {
            if (
              codemultiplicador.code != 5 &&
              codemultiplicador.code != 13 &&
              codemultiplicador.code != 14
            ) {
              subTotal =
                codemultiplicador.valor *
                element.costounitario *
                this.calcularFac(
                  codemultiplicador.code,
                  this.datosPrincipales.metroscc,
                  this.datosPrincipales.kg,
                  this.datosPrincipales.containers,
                  this.datosPrincipales.amount
                );
            } else if (
              codemultiplicador.code == 5 ||
              codemultiplicador.code == 13 ||
              codemultiplicador.code == 14
            ) {
              this.calcularValor(
                this.datosPrincipales.amount,
                this.totalDeFlete,
                codemultiplicador.code,
                codemultiplicador.code == 14 ? element.cif : element.seguro
              );
            }
          } else {
            subTotal = 0;
          }
          if (
            !(
              codemultiplicador.code == 5 &&
              codemultiplicador.code == 13 &&
              codemultiplicador.code == 14 &&
              element.esorigenflag
            )
          ) {
            cost.push({
              id: element.id,
              id_proveedor: element.id_proveedor,
              id_multiplicador: element.id_multiplicador,
              // concepto: element.nameservice,
              nameservice: element.nameservice,
              costounitario: element.costounitario,
              esorigenflag: element.esorigenflag,
              eslocalflag: element.eslocalflag,
              esaduanaflag: element.esaduanaflag,
              esalmacenflag: element.esalmacenflag,
              esopcionflag: element.esopcionflag,
              esventaflag: element.esventaflag,
              status: element.status,
              cif: element.cif,
              seguro: element.seguro,
              minimo: element.minimo ? element.minimo : 0,
              subtotal: subTotal,
            });
          }
        });

        /* GUARDAR NOTAS */
        let notas = [];

        this.datosPrincipales.notesFixed.forEach((element) => {
          notas.push({
            id: element.id ? element.id : null,
            description: element.description,
            esprincipalflag: 1,
            esincluyeflag: 0,
            esnoincluyeflag: 0,
            status: element.state ? (element.state == 1 ? true : false) : 0,
          });
        });
        /* GUARDAR CONTENEDORES */
        let cont = [];
        if (
          this.datosPrincipales.idshipment == 2 ||
          this.datosPrincipales.idshipment == 5
        ) {
          this.datosPrincipales.containers.forEach((element) => {
            cont.push({
              id: element.idPrincipal,
              id_contenedor: element.id,
              cantidad: element.cantidad,
            });
          });
        } else {
          cont = [];
        }
        /**GUARDAR GANANCIA PARA CASILLERO */
        let ganancia = 0;
        if (this.quoteSales) {
          this.quoteSales.forEach((element) => {
            ganancia += parseFloat(element.monto);
          });
        }
        /**GUARDAR GANANCIA PARA CASILLERO */
        let ventascasillero = [];
        this.quoteSales.forEach((element) => {
          ventascasillero.push({
            id: this.actualizacionCasillero ? "" : element.id,
            id_quoteSales: element.id,
            description: element.description,
            monto: element.monto ? element.monto : 0,
          });
        });
        let data = {
          fullflag: true,
          id_quote: this.$route.params.id,
          statusUpdated: this.statusUpdate,
          id_entitie: this.datosPrincipales.idCliente,
          idVendedor: this.datosPrincipales.identities,
          idPricing: this.datosPrincipales.idPricing,
          idsentido: this.datosPrincipales.idmodality,
          idtipocarga: this.datosPrincipales.idshipment.value
            ? this.datosPrincipales.idshipment.value
            : this.datosPrincipales.idshipment,
          idincoterms: this.datosPrincipales.idincoterm,
          idorigen: this.datosPrincipales.idPortBegin,
          iddestino: this.datosPrincipales.idPortEnd,
          numerobultos: this.datosPrincipales.bultos,
          peso: this.datosPrincipales.kg,
          volumen: this.datosPrincipales.metroscc,
          statusquote: this.datosPrincipales.idstatus,
          monto: this.datosPrincipales.amount
            ? this.datosPrincipales.amount
            : 0,
          serviciocotizacion: serv,
          costocotizacion: cost,
          notacosto: notas,
          contenedores: cont,
          proveedor: this.datosPrincipales.proveedor,
          telefonoproveedor: this.datosPrincipales.telefonoproveedor,
          direccionproveedor: this.datosPrincipales.direccionproveedor,
          idprovincia: this.datosPrincipales.idprovincia,
          iddistrito: this.datosPrincipales.iddistrito,
          descripcionMercancia: this.datosPrincipales.descripcioncarga
            ? this.datosPrincipales.descripcioncarga
            : "",
          impuestos: this.datosPrincipales.impuestos,
          id_marketing: this.datosPrincipales.idmarketing,
          fecha_fin: this.datosPrincipales.fecha_fin,
          tiempo_transito: this.datosPrincipales.tiempo_transito,
          ventascasillerodetalles: ventascasillero,
          ganancia: ganancia,
        };
        this.obtenerDataApi(
          "post",
          process.env.VUE_APP_URL_MAIN + "putQuote/" + this.$route.params.id,
          data
        );

        // this.subirArchivos(this.$route.params.id, this.archivos, 0);
        setTimeout(() => {
          this.$router.push({
            name: "verCotizacionVista",
            params: {
              id: this.$route.params.id,
            },
          });
        }, 3000);
      }
    },

    async getTown(id) {
      let valores = await this.obtenerDataApi(
        "post",
        process.env.VUE_APP_URL_MAIN + "getTown",
        { idCity: id }
      );
      let distritos = [];
      valores.forEach((element) => {
        distritos.push({
          value: element.id,
          text: element.name,
        });
      });
      this.setDistritos(distritos);
    },
    async getItemsServicesDetails() {
      let data = {
        id_modality: this.datosPrincipales.idmodality,
        id_shipment: this.datosPrincipales.idshipment.value
          ? this.datosPrincipales.idshipment.value
          : this.datosPrincipales.idshipment,
        id_incoterms: this.datosPrincipales.idincoterm,
        id_branch: JSON.parse(localStorage.getItem("branch")),
      };

      let valores = await this.obtenerDataApi(
        "post",
        process.env.VUE_APP_URL_MAIN + "getItemsServicesDetails", //9800
        data
      );
      let servicios = [];
      for (let i = 0; i < valores.length; i++) {
        valores[i].groupservice.forEach((element) => {
          servicios.push({
            id_groupservices: element.id_groupservices,
            id_incoterms: element.id_incoterms,
            id_modality: element.id_modality,
            id_multiplicador: element.id_multiplicador
              ? element.id_multiplicador
              : "N",
            id_services: element.id_services,
            id_shipment: element.id_shipment,
            namebegend: element.namebegend,
            namegroupservice: element.namegroupservice,
            namemultiplicador: element.namemultiplicador,
            namerole: element.namerole,
            costounitario: element.price ? element.price : 0,
            id: 0,
            id_begend: element.id_begend,
            nameservice: element.nameservice,
            status: element.status,
            id_role: element.id_role,
            statedelete: 0,
            servicioFlag: 1,
            costosflag: 0,
            cif: parseFloat(0.35),
            seguro: parseFloat(0.45),
            id_proveedor: element.id_proveedor ? element.id_proveedor : 0,
            esopcionflag: 1,
            esventaflag: 0,
            //
            esorigenflag:
              element.id_modality == 1
                ? element.id_begend == 1 ||
                  element.id_begend == 3 ||
                  element.id_begend == 6 ||
                  element.id_begend == 8
                  ? 1
                  : 0
                : //  element.id_begend == 1 ||
                element.id_begend == 6 /*||
                  element.id_begend == 6 ||
                  element.id_begend == 8*/
                ? 1
                : 0,
            eslocalflag:
              element.id_modality == 1
                ? (element.id_begend == 2 || element.id_begend == 7) &&
                  element.id_role != 4 &&
                  element.id_role != 3 &&
                  element.id_role != 7
                  ? 1
                  : 0
                : // element.id_begend == 1 ||
                element.id_begend == 3 &&
                  element.id_role != 4 &&
                  element.id_role != 3 &&
                  element.id_role != 7
                ? 1
                : 0,

            esaduanaflag:
              element.id_modality == 1
                ? (element.id_begend == 2 || element.id_begend == 7) &&
                  (element.id_role == 4 || element.id_role == 3)
                  ? 1
                  : 0
                : element.id_begend == 1 &&
                  (element.id_role == 4 || element.id_role == 3)
                ? 1
                : 0,
            esalmacenflag:
              element.id_modality == 1
                ? (element.id_begend == 2 || element.id_begend == 7) &&
                  element.id_role == 7
                  ? 1
                  : 0
                : element.id_begend == 7 && element.id_role == 7
                ? 1
                : 0,
            //
          });
          servicios.push({
            id_groupservices: element.id_groupservices,
            id_incoterms: element.id_incoterms,
            id_modality: element.id_modality,
            id_multiplicador: element.id_multiplicador
              ? element.id_multiplicador
              : "N",
            id_services: element.id_services,
            id_shipment: element.id_shipment,
            namebegend: element.namebegend,
            namegroupservice: element.namegroupservice,
            namemultiplicador: element.namemultiplicador,
            namerole: element.namerole,
            costounitario: 0,
            id: 0,
            id_begend: element.id_begend,
            nameservice: element.nameservice,
            status: element.status,
            id_role: element.id_role,
            cif: 0,
            seguro: 0,
            statedelete: 0,
            costosflag: 0,
            id_proveedor: element.id_proveedor,
            esventaflag: 1,
            esorigenflag:
              element.id_modality == 1
                ? element.id_begend == 1 ||
                  element.id_begend == 3 ||
                  element.id_begend == 6 ||
                  element.id_begend == 8
                  ? 1
                  : 0
                : //  element.id_begend == 1 ||
                element.id_begend == 6 /*||
                  element.id_begend == 6 ||
                  element.id_begend == 8*/
                ? 1
                : 0,
            eslocalflag:
              element.id_modality == 1
                ? (element.id_begend == 2 || element.id_begend == 7) &&
                  element.id_role != 4 &&
                  element.id_role != 3 &&
                  element.id_role != 7
                  ? 1
                  : 0
                : // element.id_begend == 1 ||
                element.id_begend == 3 &&
                  element.id_role != 4 &&
                  element.id_role != 3 &&
                  element.id_role != 7
                ? 1
                : 0,

            esaduanaflag:
              element.id_modality == 1
                ? (element.id_begend == 2 || element.id_begend == 7) &&
                  (element.id_role == 4 || element.id_role == 3)
                  ? 1
                  : 0
                : element.id_begend == 1 &&
                  (element.id_role == 4 || element.id_role == 3)
                ? 1
                : 0,
            esalmacenflag:
              element.id_modality == 1
                ? (element.id_begend == 2 || element.id_begend == 7) &&
                  element.id_role == 7
                  ? 1
                  : 0
                : element.id_begend == 7 && element.id_role == 7
                ? 1
                : 0,
            //
          });
        });
      }
      this.guardaCostos(servicios);
      this.modalCargando = false;
    },

    guardaCostos(servicios) {
      this.setCostos(servicios);
    },

    recargar() {
      Swal.fire({
        html: `¿ESTÁS SEGURO QUE DESEAS?`,
        titleText: `SI CONTINUAS SE BORRÁ TODOS LOS SERVICIOS DE LA VENTA Y SUS COSTOS`,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "NO CONTINUAR",
        denyButtonText: `CONTINUAR`,
        cancelButtonText: `NO CONTINUAR`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        // if (result.isConfirmed) {
        //   this.getMultiplicador(
        //     this.datosPrincipales.idshipment.value
        //       ? this.datosPrincipales.idshipment.value
        //       : this.datosPrincipales.idshipment
        //   );
        //   this.getItemsServices();
        //   this.statusUpdate = true;
        // } else
        if (result.isDenied) {
          this.getMultiplicador({
            id: this.datosPrincipales.idshipment.value
              ? this.datosPrincipales.idshipment.value
              : this.datosPrincipales.idshipment,
          });
          this.getItemsServices();
          this.statusUpdate = true;
        }
      });
    },
    async copiarCotizacion() {
      Swal.fire({
        title: "ADVERTENCIA",
        text: "¿Desea Crear una copia de esta cotización?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        denyButtonText: `Cancelar`,
      }).then((result) => {
        if (result.isConfirmed) {
          let serv = [];
          this.services
            .filter((v) => v.esventaflag == 0)
            .forEach((element) => {
              serv.push({
                idBegEnd: element.id_begend,
                nameservice: element.namegroupservice,
                statusService: element.status,
                id_groupservices: element.id_groupservices
                  ? element.id_groupservices
                  : "",
                codegroupservices: element.codegroupservices,
                codeItemservices: element.codeItemservices,
                id_begend: element.id_begend,
              });
            });
          /* GUARDAR COSTOS */
          let cost = [];

          this.costos
            .filter((v) => v.statedelete == 0)
            .forEach((element) => {
              let codemultiplicador = element.id_multiplicador
                ? this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  )[0]
                : [];
              let subTotal = 0;
              if (codemultiplicador) {
                if (
                  codemultiplicador.code != 5 &&
                  codemultiplicador.code != 13 &&
                  codemultiplicador.code != 14
                ) {
                  subTotal =
                    codemultiplicador.valor *
                    element.costounitario *
                    this.calcularFac(
                      codemultiplicador.code,
                      this.datosPrincipales.metroscc,
                      this.datosPrincipales.kg,
                      this.datosPrincipales.containers,
                      this.datosPrincipales.amount
                    );
                } else if (
                  codemultiplicador.code == 5 ||
                  codemultiplicador.code == 13 ||
                  codemultiplicador.code == 14
                ) {
                  this.calcularValor(
                    this.datosPrincipales.amount,
                    this.totalDeFlete,
                    codemultiplicador.code,
                    codemultiplicador.code == 14 ? element.cif : element.seguro
                  );
                }
              } else {
                subTotal = 0;
              }
              if (
                !(
                  codemultiplicador.code == 5 &&
                  codemultiplicador.code == 13 &&
                  codemultiplicador.code == 14 &&
                  element.esorigenflag
                )
              ) {
                cost.push({
                  id_proveedor: element.id_proveedor,
                  id_multiplicador: element.id_multiplicador,
                  concepto: element.nameservice,
                  costounitario: element.costounitario,
                  minimo: element.minimo ? element.minimo : 0,
                  esorigenflag: element.esorigenflag,
                  eslocalflag: element.eslocalflag,
                  esaduanaflag: element.esaduanaflag,
                  esalmacenflag: element.esalmacenflag,
                  esopcionflag: element.esventaflag == 1 ? 1 : 0,
                  status: element.status,
                  esventaflag: element.esventaflag,
                  cif: element.cif,
                  seguro: element.seguro,
                  subtotal: subTotal,
                });
              }
            });
          /* GUARDAR NOTAS */
          let notas = [];

          this.datosPrincipales.notesFixed
            .filter((v) => v.state == 1)
            .forEach((element) => {
              notas.push({
                descripcion: element.description,
                esprincipalflag: 1,
                esincluyeflag: 0,
                esnoincluyeflag: 0,
                status: element.state ? element.state : 1,
              });
            });

          /* GUARDAR CONTENEDORES */
          let cont = [];
          if (this.datosPrincipales.idshipment.value == 2) {
            this.datosPrincipales.containers.forEach((element) => {
              cont.push({
                id_contenedor: element.id,
                cantidad: element.cantidad,
              });
            });
          } else {
            cont = [];
          }
          let data = {
            fullflag: true,
            id_entitie: this.datosPrincipales.idCliente,
            idVendedor: this.datosPrincipales.identities,
            idsentido: this.datosPrincipales.idmodality,
            idtipocarga: this.datosPrincipales.idshipment,
            idincoterms: this.datosPrincipales.idincoterm,
            idorigen: this.datosPrincipales.idPortBegin,
            iddestino: this.datosPrincipales.idPortEnd,
            numerobultos: this.datosPrincipales.bultos,
            peso: this.datosPrincipales.kg,
            volumen: this.datosPrincipales.metroscc,
            statusquote: this.datosPrincipales.idstatus,
            monto: this.datosPrincipales.amount,
            serviciocotizacion: serv,
            costocotizacion: cost,
            notacosto: notas,
            contenedores: cont,
            proveedor: this.datosPrincipales.proveedor,
            telefonoproveedor: this.datosPrincipales.telefonoproveedor,
            direccionproveedor: this.datosPrincipales.direccionproveedor,
            iddistrito: this.datosPrincipales.iddistrito,
            idprovincia: this.datosPrincipales.idprovincia,
            impuestos: this.datosPrincipales.impuestos,
            id_marketing: this.datosPrincipales.idmarketing,
            descripcionMercancia: this.datosPrincipales.descripcioncarga
              ? this.datosPrincipales.descripcioncarga
              : "",
            fecha_fin: null,
            tiempo_transito: null,
          };
          let token = JSON.parse(localStorage.getItem("token"));
          let config = {
            method: "post",
            url: process.env.VUE_APP_URL_MAIN + "setQuote",
            headers: {
              "auth-token": token,
              "Content-Type": "application/json",
            },
            data: data,
          };

          axios(config).then((response) => {
            Swal.fire("CORRECTO", response.data.msg, "success").then(
              (result) => {
                if (result.isConfirmed) {
                  this.$router
                    .push({
                      path: `/editar_cotizacion/${response.data.insertId}`,
                    })
                    .catch(() => {});
                }
              }
            );
          });
        }
      });
    },
    ...mapMutations([
      "setbegEndList",
      "setQuoteStatus",
      "setEntities",
      "setModality",
      "setShipment",
      "setIncoterms",
      "setPortBegin",
      "setPortEnd",
      "setServices",
      "setRole",
      "setMultiplicador",
      "setContainersList",
      "setDatosClientes",
      "opcioneMenuLista",
      "setregistroCompleto",
      "setDialog",
      "setCostos",
      "guardarDatosVer",
      "setLlamadas",
      "setInstructivo",
      "setFactor",
      "setProvincias",
      "setDistritos",
      "setEditarflag",
      "setListFiile",
      "setArchivosFlag",
      "setearDatos",
      "setQuoteSales",
    ]),
  },
  computed: {
    ...mapState([
      "datosPrincipales",
      "stepCarga",
      "stepServicio",
      "stepCosto",
      "stepNota",
      "stepInstructivo",
      "datosServiciosFlag",
      "nuevoUsuarioFlag",
      "cudFlag",
      "services",
      "costos",
      "dialog",
      "containersList",
      "factor",
      "editarflag",
      "totalVenta",
      "totalOpcion",
      "archivos",
      "actualizarListaArchivos",
      "multiplicador",
      "totalDeFlete",
      "quoteSales",
    ]),
  },

  created() {},
  beforeMount() {
    this.setEditarflag(true);
    let opciones = [
      {
        id: 1,
        nombre: "Datos Principales",
        class: "active",
      },
      {
        id: 4,
        nombre: "Costos Cotización",
      },
      {
        id: 5,
        nombre: "Notas de Costos",
      },
      {
        id: 6,
        nombre: "Instructivo",
      },
    ];
    this.opcioneMenuLista(opciones);
    this.getQuoteStatus();
    this.getEntities();
    this.getModality();
    this.getShipment();
    this.getIncoterms();
    this.getModuleRole();
    this.getContainersList();
    this.obtenerDatosCliente();
    this.setregistroCompleto(false);
    this.getBegEndList();
    this.obtenerDatos();
    this.getFilesQuote(this.$route.params.id);
    this.getMarketingList();
  },
  beforeCreate() {},
  updated() {},
  watch: {
    nuevoUsuarioFlag() {
      this.obtenerDatosCliente();
    },

    stepCarga() {
      this.getMultiplicador(
        this.datosPrincipales.idshipment.value
          ? this.datosPrincipales.idshipment.value
          : this.datosPrincipales.idshipment
      );
      this.getItemsServices();
      this.statusUpdate = true;
    },
    actualizarListaArchivos() {
      setTimeout(() => {
        this.obtenerIdTab(5);
      }, 1900);
    },
  },
};
</script>

<style>
.tabFixeado {
  position: absolute !important; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  width: 100%; /* Full width */
}
.perdida {
  background: red !important;
  color: white !important;
  font-weight: bold;
  font-size: large;
}
.ganancia {
  background: rgb(32, 110, 32) !important;
  color: white !important;

  font-weight: bold;
  font-size: large;
}
</style>
